import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import TimeSpentView from "../views/TimeSpentView.vue";
import AlertsControlView from "../views/AlertsControlView.vue";
import NotFoundView from "../views/NotFoundView.vue";
import TimeSpentMonthlyView from "@/views/TimeSpentMonthlyView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/timespent",
    name: "TimeSpent",
    component: TimeSpentView,
  },
  {
    path: "/timeSpent-Monthly",
    name: "TimeSpent-Monthly",
    component: TimeSpentMonthlyView,
  },
  {
    path: "/alerts",
    name: "Alerts",
    component: AlertsControlView,
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFoundView,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
