<template>
  <v-container fill-height fluid>
    <v-row align="center" class="fill-height">
      <v-col class="col-12">
        <v-img
          class="mx-auto"
          max-width="600px"
          src="/images/logo-jf.svg"
        ></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style lang="scss" scoped></style>
