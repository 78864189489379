<template>
  <div></div>
</template>

<script>
import "vue-toastification/dist/index.css";
import axios from "axios";

export default {
  name: "ApiErrorCatcher",
  data: function () {
    return {
      interceptor: null,
    };
  },
  beforeCreate() {
    this.interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (typeof error !== "undefined") {
          if (error.response.status === 500 && error.response.data !== "") {
            if (typeof error.response.data === "object") {
              if (typeof error.response.data.detail !== "undefined") {
                this.$toast.error(error.response.data.detail);
                return;
              }
              this.$toast.error(JSON.stringify(error.response.data));
              return;
            }
            this.$toast.error(error.response.data);
            return;
          }
          this.$toast.error(error.message);
        }
      }
    );
  },
  destroyed() {
    axios.interceptors.request.eject(this.interceptor);
  },
};
</script>

<style scoped></style>
