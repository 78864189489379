<template>
  <v-container fluid>
    <v-row>
      <v-col class="pb-0" cols="12">
        <v-autocomplete
          v-model="selectedProjects"
          :items="projects"
          chips
          item-text="name"
          item-value="id"
          label="Projekte"
          multiple
          prepend-icon="mdi-script-text"
        >
          <template v-slot:selection="data">
            <v-chip
              :input-value="data.selected"
              close
              v-bind="data.attrs"
              @click="data.select"
              @click:close="removeProject(data.item)"
            >
              <v-avatar left>
                <v-img :src="data.item.avatar"></v-img>
              </v-avatar>
              {{ data.item.name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-avatar>
                <img alt="Project Logo" :src="data.item.avatar" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.name"></v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pb-0" cols="4">
        <v-autocomplete
          v-model="selectedLabels"
          :items="labels"
          chips
          item-text="title"
          item-value="id"
          label="Labels"
          multiple
          prepend-icon="mdi-script-text"
        >
          <template v-slot:selection="data">
            <v-chip
              :input-value="data.selected"
              close
              v-bind="data.attrs"
              :color="data.item.color"
              @click="data.select"
              @click:close="removeLabel(data.item)"
            >
              {{ data.item.title }}
            </v-chip>
          </template>
          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-chip
                  :input-value="data.selected"
                  v-bind="data.attrs"
                  :color="data.item.color"
                >
                  {{ data.item.title }}
                </v-chip>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col class="pb-4" cols="4">
        <v-radio-group v-model="issueStatus">
          <v-radio label="All" value=""></v-radio>
          <v-radio label="Open" value="1"></v-radio>
          <v-radio label="Closed" value="2"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="pb-1" cols="2">
        <v-checkbox
          label="Only UnderEstimated"
          value="1"
          v-model="onlyUnderEstimated"
        ></v-checkbox>
        <v-row>
          <v-col>
            <v-radio-group v-model="overviewType">
              <v-radio label="overview Users" value="users"> </v-radio>
              <v-radio label="overview Projects" value="projects"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" sm="2">
        <v-menu
          v-model="dateFromMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="40px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateFrom"
              label="Datum von"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateFrom"
            @input="dateFromMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4" sm="2">
        <v-menu
          v-model="dateToMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          min-width="40px"
          offset-y
          transition="scale-transition"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dateTo"
              label="Datum bis"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateTo"
            @input="dateToMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="loadDataDefault"
          >AUSWERTEN</v-btn
        >
      </v-col>
      <v-col cols="1" sm="1"></v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="warning" large @click="setTimePeriod('thisWeek')">
          THIS WEEK
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="warning" large @click="setTimePeriod('lastWeek')">
          LAST WEEK
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="warning" large @click="setTimePeriod('thisMonth')">
          THIS MONTH
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="warning" large @click="setTimePeriod('lastMonth')">
          LAST MONTH
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="warning" large @click="setTimePeriod('thisYear')">
          THIS YEAR
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="warning" large @click="setTimePeriod('lastYear')">
          LAST YEAR
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-data-table
          v-if="!loading && timeByLabel"
          v-model="selected"
          :disable-pagination="true"
          :disable-sort="true"
          :hide-default-footer="true"
          :items="timeByLabel"
          item-key="name"
        >
          <template v-slot:item="data">
            <span style="padding: 5px">
              <v-chip
                :input-value="data.selected"
                v-bind="data.attrs"
                :color="data.item.color"
              >
                {{ data.item.name }}
                {{ data.item.value }}%
              </v-chip>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12">
        <v-skeleton-loader
          v-if="loading"
          ref="skeleton"
          :boilerplate="false"
          :tile="false"
          class="mx-auto"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-data-table
          v-if="!loading && result"
          v-model="selected"
          :disable-pagination="true"
          :headers="headers"
          :hide-default-footer="true"
          :items="result"
          :items-per-page="5"
          class="elevation-1"
          item-key="name"
          show-select
        >
          <template v-slot:[`item.timeSpent`]="{ item }">
            {{ humanTime(item.timeSpent) }}
          </template>
          <template v-slot:[`item.issues`]="{ item }">
            <IssueList :item="item" />
          </template>
          <template v-slot:[`body.append`]>
            <tr>
              <td></td>
              <td class="text-right">Summe:</td>
              <td class="text-right">{{ totalTime }}</td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
      <v-col col="12">
        <v-data-table
          v-if="!loading && result && days && overviewType === 'users'"
          v-model="selected"
          :disable-pagination="true"
          :disable-sort="true"
          :headers="daysHeaders"
          :hide-default-footer="true"
          :items="result"
          class="elevation-1"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  AnalyticsApi,
  LabelsResponse,
  ProjectResponse,
  TimeSpentResponse,
  TimeByLabel,
} from "@/api";
import IssueList from "@/components/IssueList.vue";
import { parseTime } from "@/tools/humanTime";

export default defineComponent({
  name: "TimeSpentView",
  components: { IssueList },
  data() {
    return {
      loading: false as boolean,
      dateFrom: "" as string,
      dateFromMenu: false as boolean,
      dateTo: "" as string,
      dateToMenu: false as boolean,
      result: null as null | Array<TimeSpentResponse>,
      timeByLabel: [] as Array<TimeByLabel>,
      selected: [],
      days: false,
      selectedProjects: [] as Array<number>,
      selectedLabels: [] as Array<number>,
      summedByLabels: false as boolean,
      issueStatus: "" as string,
      overviewType: "users" as string,
      onlyUnderEstimated: false as boolean,
      singleSelect: null,
      projects: null as null | Array<ProjectResponse>,
      labels: null as null | Array<LabelsResponse>,
      headers: [
        { text: "Entwickler/Projekt", value: "name", width: "150px" },
        { text: "Zeit", value: "timeSpent", width: "200px", align: "end" },
        { text: "Tickets", value: "issues" },
      ],
      daysHeaders: [
        { text: "Monday", value: "days.mon" },
        { text: "Tuesday", value: "days.tue" },
        { text: "Wednesday", value: "days.wed" },
        { text: "Thursday", value: "days.thu" },
        { text: "Friday", value: "days.fri" },
      ],
    };
  },
  computed: {
    totalTime(): string {
      return parseTime(
        this.selected.reduce(function (a: number, b: TimeSpentResponse) {
          return a + ((b.timeSpent as unknown as number) ?? 0);
        }, 0)
      );
    },
  },
  methods: {
    async loadData(): Promise<void> {
      this.selected = [];
      this.loading = true;

      this.result = (
        await new AnalyticsApi().getAnalyticsTimeSpent(
          this.dateFrom,
          this.dateTo,
          this.selectedProjects,
          this.selectedLabels,
          this.issueStatus,
          this.overviewType,
          this.onlyUnderEstimated,
          false
        )
      ).data;
      try {
        this.timeByLabel = (
          await new AnalyticsApi().getAnalyticsTimeSpent(
            this.dateFrom,
            this.dateTo,
            this.selectedProjects,
            this.selectedLabels,
            this.issueStatus,
            this.overviewType,
            this.onlyUnderEstimated,
            true
          )
        ).data;
      } finally {
        this.loading = false;
      }
    },
    humanTime(time: number) {
      return parseTime(time);
    },
    loadDataDefault() {
      this.days = false;
      this.loadData();
    },
    setTimePeriod(period: string) {
      const today = new Date();
      let thisMonth = today.getMonth() + 1; //see getMonth Documentation
      let lastMonth = thisMonth - 1;
      let thisYear = today.getFullYear();
      let lastYear = thisYear - 1;
      let thisWeekStart = new Date(
        today.setDate(today.getDate() - today.getDay())
      ).toLocaleDateString("sv-SE");
      let thisWeekEnd = new Date(
        today.setDate(today.getDate() - today.getDay() + 6)
      ).toLocaleDateString("sv-SE");
      let lastWeekStart = new Date(
        today.setDate(today.getDate() - today.getDay() - 7)
      ).toLocaleDateString("sv-SE");
      let lastWeekEnd = new Date(
        today.setDate(today.getDate() - today.getDay() + 6)
      ).toLocaleDateString("sv-SE");

      this.days = false;

      switch (period) {
        case "thisWeek":
          this.dateFrom = thisWeekStart;
          this.dateTo = thisWeekEnd;
          this.days = true;
          break;
        case "lastWeek":
          this.dateFrom = lastWeekStart;
          this.dateTo = lastWeekEnd;
          this.days = true;
          break;
        case "thisMonth":
          this.dateFrom = thisYear + "-" + thisMonth + "-01";
          this.dateTo = thisYear + "-" + thisMonth + "-31";
          break;
        case "lastMonth":
          this.dateFrom =
            thisYear + "-" + lastMonth.toString().padStart(2, "0") + "-01";
          this.dateTo =
            thisYear + "-" + lastMonth.toString().padStart(2, "0") + "-31";
          break;
        case "thisYear":
          this.dateFrom = thisYear + "-01-01";
          this.dateTo = thisYear + "-12-31";
          break;
        case "lastYear":
          this.dateFrom = lastYear + "-01-01";
          this.dateTo = lastYear + "-12-31";
          break;
      }
      this.loadData();
    },
    removeProject(item: ProjectResponse) {
      const index = this.selectedProjects.indexOf(item.id as number);
      if (index >= 0) this.selectedProjects.splice(index, 1);
    },
    removeLabel(item: LabelsResponse) {
      const index = this.selectedLabels.indexOf(item.id as number);
      if (index >= 0) this.selectedLabels.splice(index, 1);
    },
  },
  async mounted() {
    this.projects = (await new AnalyticsApi().getAnalyticsProjects()).data;
    this.labels = (await new AnalyticsApi().getAnalyticsLabels()).data;
  },
});
</script>
