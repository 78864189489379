<template>
  <v-container fluid>
    <v-row>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod('thisYear')"
          >THIS YEAR
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod(lastYear)"
          >LAST YEAR
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod(twoYearsAgo)"
          >{{ twoYearsAgo }}
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod(threeYearsAgo)"
          >{{ threeYearsAgo }}
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod(fourYearsAgo)"
          >{{ fourYearsAgo }}
        </v-btn>
      </v-col>
      <v-col cols="1" sm="1">
        <v-btn block color="primary" large @click="setTimePeriod(fiveYearsAgo)"
          >{{ fiveYearsAgo }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-data-table
          v-if="!loading"
          :disable-pagination="false"
          :disable-sort="true"
          :hide-default-footer="true"
          :items-per-page="1"
          :items="result"
          item-key="name"
        >
          <template v-slot:item="data">
            <span style="padding: 5px">
              <v-chip>
                {{ data.item.year }}
              </v-chip>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="12">
        <v-skeleton-loader
          v-if="loading"
          ref="skeleton"
          :boilerplate="false"
          :tile="false"
          class="mx-auto"
          type="list-item-three-line"
        ></v-skeleton-loader>
        <v-data-table
          v-if="!loading && result"
          :disable-pagination="true"
          :headers="headers"
          :hide-default-footer="true"
          :items="result"
          class="elevation-1"
          item-key="name"
        >
          <template v-slot:[`item.feature`]="{ item }">
            {{ humanTimeCustom(item.feature) }}
            {{ item.featurePer }}
          </template>
          <template v-slot:[`item.assistance`]="{ item }">
            {{ humanTimeCustom(item.assistance) }}
            {{ item.assistancePer }}
          </template>
          <template v-slot:[`item.error`]="{ item }">
            {{ humanTimeCustom(item.error) }}
            {{ item.errorPer }}
          </template>
          <template v-slot:[`item.sum`]="{ item }">
            {{ humanTimeCustom(item.sum) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AnalyticsApi, TimeSpentMonthlyResponse } from "@/api";
import { parseTimeCustom } from "@/tools/humanTime";
export default defineComponent({
  name: "TimeSpentMonthlyView",
  data() {
    return {
      loading: false as boolean,
      dateFrom: "" as string,
      dateFromMenu: false as boolean,
      dateTo: "" as string,
      lastYear: (new Date().getFullYear() - 1) as number,
      twoYearsAgo: (new Date().getFullYear() - 2) as number,
      threeYearsAgo: (new Date().getFullYear() - 3) as number,
      fourYearsAgo: (new Date().getFullYear() - 4) as number,
      fiveYearsAgo: (new Date().getFullYear() - 5) as number,
      dateToMenu: false as boolean,
      result: null as null | Array<TimeSpentMonthlyResponse>,
      singleSelect: null,
      headers: [
        { text: "Month", value: "month", width: "150px" },
        { text: "Feature", value: "feature", width: "200px", align: "end" },
        {
          text: "Assistance",
          value: "assistance",
          width: "200px",
          align: "end",
        },
        { text: "Error", value: "error", width: "200px", align: "end" },
        { text: "Sum", value: "sum", width: "200px", align: "end" },
      ],
    };
  },
  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        this.result = (
          await new AnalyticsApi().getAnalyticsTimeSpentMonthly(
            this.dateFrom,
            this.dateTo
          )
        ).data;
      } finally {
        this.loading = false;
      }
    },

    setTimePeriod(period: string) {
      const today = new Date();
      let thisYear = today.getFullYear();
      switch (period) {
        case "thisYear":
          this.dateFrom = thisYear + "-01-01";
          this.dateTo = thisYear + "-12-31";
          break;
        default:
          this.dateFrom = period + "-01-01";
          this.dateTo = period + "-12-31";
          break;
      }
      this.loadData();
    },
    humanTimeCustom(time: number) {
      return parseTimeCustom(time);
    },
    loadDataDefault() {
      this.loadData();
    },
  },
});
</script>
