import "@mdi/font/css/materialdesignicons.css";
import Vuetify from "vuetify/lib";
import { UserVuetifyPreset } from "vuetify";

const opts: UserVuetifyPreset = {
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
};

export default new Vuetify(opts);
