/* tslint:disable */
/* eslint-disable */
/**
 * jfnetwork Tools
 * Internal tools to support our workflows with automatism and reporting
 *
 * The version of the OpenAPI document: 0.0.0-dev
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {Configuration} from './configuration';
import globalAxios, {AxiosInstance, AxiosPromise, AxiosRequestConfig} from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    toPathString
} from './common';
// @ts-ignore
import {BASE_PATH, BaseAPI, COLLECTION_FORMATS, RequestArgs, RequiredError} from './base';

/**
 *
 * @export
 * @enum {string}
 */

export const AlertStatus = {
    _0: '0',
    _1: '1',
    _2: '2',
    _3: '3',
    _4: '4'
} as const;

export type AlertStatus = typeof AlertStatus[keyof typeof AlertStatus];


/**
 *
 * @export
 * @interface CheckEntity
 */
export interface CheckEntity {
    /**
     *
     * @type {string}
     * @memberof CheckEntity
     */
    'entity'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CheckEntity
     */
    'subEntity'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CheckEntity
     */
    'parent'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CheckEntity
     */
    'parentEntity'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CheckEntity
     */
    'parentSubEntity'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CheckEntity
     */
    'alertChannel'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CheckEntity
     */
    'metric'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CheckEntity
     */
    'error'?: string | null;
    /**
     *
     * @type {number}
     * @memberof CheckEntity
     */
    'errorCount'?: number;
    /**
     *
     * @type {number}
     * @memberof CheckEntity
     */
    'alertOnErrorCount'?: number;
    /**
     *
     * @type {boolean}
     * @memberof CheckEntity
     */
    'alerted'?: boolean;
    /**
     *
     * @type {AlertStatus}
     * @memberof CheckEntity
     */
    'status'?: AlertStatus | null;
    /**
     *
     * @type {string}
     * @memberof CheckEntity
     */
    'lastSeen'?: string | null;
    /**
     *
     * @type {string}
     * @memberof CheckEntity
     */
    'metricId'?: string;
}

/**
 *
 * @export
 * @interface EnvironmentResponse
 */
export interface EnvironmentResponse {
    /**
     *
     * @type {string}
     * @memberof EnvironmentResponse
     */
    'version'?: string;
    /**
     *
     * @type {InternalUser}
     * @memberof EnvironmentResponse
     */
    'user'?: InternalUser;
}

/**
 *
 * @export
 * @interface InternalUser
 */
export interface InternalUser {
    /**
     *
     * @type {string}
     * @memberof InternalUser
     */
    'name'?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof InternalUser
     */
    'roles'?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof InternalUser
     */
    'guid'?: string;
    /**
     *
     * @type {string}
     * @memberof InternalUser
     */
    'mail'?: string;
    /**
     *
     * @type {string}
     * @memberof InternalUser
     */
    'token'?: string;
    /**
     *
     * @type {string}
     * @memberof InternalUser
     */
    'imagePath'?: string;
    /**
     *
     * @type {string}
     * @memberof InternalUser
     */
    'firstname'?: string;
    /**
     *
     * @type {string}
     * @memberof InternalUser
     */
    'lastname'?: string;
    /**
     *
     * @type {string}
     * @memberof InternalUser
     */
    'username'?: string;
    /**
     *
     * @type {string}
     * @memberof InternalUser
     */
    'password'?: string | null;
    /**
     *
     * @type {string}
     * @memberof InternalUser
     */
    'salt'?: string | null;
}

/**
 *
 * @export
 * @interface ProjectResponse
 */
export interface ProjectResponse {
    /**
     *
     * @type {string}
     * @memberof ProjectResponse
     */
    'name'?: string;
    /**
     *
     * @type {number}
     * @memberof ProjectResponse
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof ProjectResponse
     */
    'avatar'?: string | null;
}

/**
 *
 * @export
 * @interface LabelsResponse
 */
export interface LabelsResponse {
    /**
     *
     * @type {number}
     * @memberof LabelsResponse
     */
    'id'?: number;
    /**
     *
     * @type {string}
     * @memberof LabelsResponse
     */
    'title'?: string;
    /**
     *
     * @type {string}
     * @memberof LabelsResponse
     */
    'color'?: string;
    /**
     *
     * @type {string}
     * @memberof LabelsResponse
     */
    'description'?: string | null;
}

/**
 *
 * @export
 * @interface ResponseError
 */
export interface ResponseError {
    /**
     *
     * @type {string}
     * @memberof ResponseError
     */
    'code'?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseError
     */
    'message'?: string;
}

/**
 *
 * @export
 * @interface TimeSpentIssue
 */
export interface TimeSpentIssue {
    /**
     *
     * @type {string}
     * @memberof TimeSpentIssue
     */
    'title'?: string | null;
    /**
     *
     * @type {string}
     * @memberof TimeSpentIssue
     */
    'path'?: string | null;
    /**
     *
     * @type {string}
     * @memberof TimeSpentIssue
     */
    'spent'?: string | null;

    /**
     *
     * @type {string}
     * @memberof TimeSpentIssue
     */
    'estimate'?: string | null;
}


/**
 *
 * @export
 * @interface TimeSpentMonthlyResponse
 */
export interface TimeSpentMonthlyResponse {
    /**
     *
     * @type {string}
     * @memberof TimeSpentMonthlyResponse
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof TimeSpentMonthlyResponse
     */
    'feature'?: string | null;
    /**
     *
     * @type {string}
     * @memberof TimeSpentMonthlyResponse
     */
    'assistance'?: string | null;
    /**
     *
     * @type {string}
     * @memberof TimeSpentMonthlyResponse
     */
    'error'?: string | null;
}

/**
 *
 * @export
 * @interface TimeSpentResponse
 */
export interface TimeSpentResponse {
    /**
     *
     * @type {string}
     * @memberof TimeSpentResponse
     */
    'name'?: string | null;
    /**
     *
     * @type {object}
     * @memberof TimeSpentResponse
     */
    'timeSpent'?: object | null;
    /**
     *
     * @type {Array<TimeSpentIssue>}
     * @memberof TimeSpentResponse
     */
    'issues'?: Array<TimeSpentIssue>;
}

/**
 *
 * @export
 * @interface TimeSpentResponse
 */
export interface TimeByLabel {
    /**
     *
     * @type {string}
     * @memberof TimeByLabel
     */
    'name'?: string | null;
    /**
     *
     * @type {string}
     * @memberof TimeByLabel
     */
    'color'?: string | null;
    /**
     *
     * @type {number}
     * @memberof TimeByLabel
     */
    'value'?: number | null;
}

/**
 * AlertsApi - axios parameter creator
 * @export
 */
export const AlertsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary delete an alert, that hasn\'t been seen for a while. You cannot delete known alerts as the alert would be instantly recreated.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppAlertDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAppAlertDelete', 'id', id)
            const localVarPath = `/api/alerts/silence/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary lists all alerts known by the alerting system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppAlertList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary mute or unmute an alert
         * @param {CheckEntity} checkEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppAlertSilence: async (checkEntity: CheckEntity, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkEntity' is not null or undefined
            assertParamExists('postAppAlertSilence', 'checkEntity', checkEntity)
            const localVarPath = `/api/alerts/silence`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkEntity, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertsApi - functional programming interface
 * @export
 */
export const AlertsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AlertsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary delete an alert, that hasn\'t been seen for a while. You cannot delete known alerts as the alert would be instantly recreated.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAppAlertDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAppAlertDelete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary lists all alerts known by the alerting system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppAlertList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CheckEntity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppAlertList(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary mute or unmute an alert
         * @param {CheckEntity} checkEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAppAlertSilence(checkEntity: CheckEntity, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAppAlertSilence(checkEntity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AlertsApi - factory interface
 * @export
 */
export const AlertsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AlertsApiFp(configuration)
    return {
        /**
         *
         * @summary delete an alert, that hasn\'t been seen for a while. You cannot delete known alerts as the alert would be instantly recreated.
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAppAlertDelete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAppAlertDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary lists all alerts known by the alerting system
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppAlertList(options?: any): AxiosPromise<Array<CheckEntity>> {
            return localVarFp.getAppAlertList(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary mute or unmute an alert
         * @param {CheckEntity} checkEntity
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAppAlertSilence(checkEntity: CheckEntity, options?: any): AxiosPromise<void> {
            return localVarFp.postAppAlertSilence(checkEntity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AlertsApi - object-oriented interface
 * @export
 * @class AlertsApi
 * @extends {BaseAPI}
 */
export class AlertsApi extends BaseAPI {
    /**
     *
     * @summary delete an alert, that hasn\'t been seen for a while. You cannot delete known alerts as the alert would be instantly recreated.
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public deleteAppAlertDelete(id: string, options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).deleteAppAlertDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary lists all alerts known by the alerting system
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public getAppAlertList(options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).getAppAlertList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary mute or unmute an alert
     * @param {CheckEntity} checkEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public postAppAlertSilence(checkEntity: CheckEntity, options?: AxiosRequestConfig) {
        return AlertsApiFp(this.configuration).postAppAlertSilence(checkEntity, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    function newRequest(localVarPath: string, options: AxiosRequestConfig<any>) {
        const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
        let baseOptions;
        if (configuration) {
            baseOptions = configuration.baseOptions;
        }

        const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options};
        const localVarHeaderParameter = {} as any;
        const localVarQueryParameter = {} as any;


        setSearchParams(localVarUrlObj, localVarQueryParameter);
        let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
        localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

        return {
            url: toPathString(localVarUrlObj),
            options: localVarRequestOptions,
        };
    }

    return {
        /**
         *
         * @summary get all internal projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsProjects: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            return newRequest(localVarPath, options);
        },
        /**
         *
         * @summary get all internal Labels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsLabels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/analytics/labels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            return newRequest(localVarPath, options);
        },
        /**
         *
         * @summary get time spent analytics for employees
         * @param {string} from
         * @param {string} to
         * @param {Array<number>} [projects]
         * @param {Array<number>} [labels]
         * @param {string} [issueStatus]
         * @param overviewType
         * @param onlyUnderEstimated
         * @param {boolean} [summedByLabels]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalyticsTimeSpent: async (from: string, to: string, projects?: Array<number>, labels?: Array<number>,
                                      issueStatus ?: string,
                                      overviewType ?: string,
                                      onlyUnderEstimated ?: boolean,
                                      summedByLabels ?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAnalyticsTimeSpent', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAnalyticsTimeSpent', 'to', to)
            const localVarPath = `/api/analytics/time-spent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (projects) {
                localVarQueryParameter['projects'] = projects.join(COLLECTION_FORMATS.csv);
            }

            if (labels) {
                localVarQueryParameter['labels'] = labels.join(COLLECTION_FORMATS.csv);
            }

            if (issueStatus) {
                localVarQueryParameter['issueStatus'] = issueStatus;
            }

            if (overviewType) {
                localVarQueryParameter['overviewType'] = overviewType;
            }

            if (onlyUnderEstimated) {
                localVarQueryParameter['onlyUnderEstimated'] = 1;
            }


            localVarQueryParameter['summed'] = summedByLabels;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        getAnalyticsTimeSpentMonthly: async (from: string, to: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getAnalyticsTimeSpent', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('getAnalyticsTimeSpent', 'to', to)
            const localVarPath = `/api/analytics/time-spent-monthly`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary get all internal projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsProjects(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsProjects(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary get all internal labels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsLabels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsLabels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @summary get time spent analytics for employees
         * @param {string} from
         * @param {string} to
         * @param {Array<number>} [projects]
         * @param labels
         * @param {string} [issueStatus]
         * @param overviewType
         * @param onlyUnderEstimated
         * @param {boolean} [summedByLabels]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalyticsTimeSpent(from: string, to: string, projects?: Array<number>, labels?: Array<number>,
                                    issueStatus ?: string,
                                    overviewType ?: string,
                                    onlyUnderEstimated ?: boolean,
                                    summedByLabels ?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSpentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsTimeSpent(from, to, projects, labels,
                issueStatus,
                overviewType,
                onlyUnderEstimated,
                summedByLabels, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        async getAnalyticsTimeSpentMonthly(from: string, to: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimeSpentMonthlyResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalyticsTimeSpentMonthly(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     *
     * @summary get all internal projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAnalyticsProjects(options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).getAnalyticsProjects(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary get all internal labels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAnalyticsLabels(options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).getAnalyticsLabels(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     *
     * @summary get time spent analytics for employees
     * @param {string} from
     * @param {string} to
     * @param {Array<number>} [projects]
     * @param {Array<number>} [labels]
     * @param issueStatus
     * @param overviewType
     * @param onlyUnderEstimated
     * @param summedByLabels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getAnalyticsTimeSpent(from: string, to: string, projects?: Array<number>, labels?: Array<number>,
                                 issueStatus ?: string,
                                 overviewType ?: string,
                                 onlyUnderEstimated ?: boolean,
                                 summedByLabels ?: boolean, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).getAnalyticsTimeSpent(from, to, projects, labels, issueStatus, overviewType, onlyUnderEstimated, summedByLabels, options).then((request) => request(this.axios, this.basePath));
    }


    /**
     *
     * @param from
     * @param to
     * @param options
     */
    public getAnalyticsTimeSpentMonthly(from: string, to: string, options?: AxiosRequestConfig) {
        return AnalyticsApiFp(this.configuration).getAnalyticsTimeSpentMonthly(from, to, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocJsonGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/doc.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocJsonGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocJsonGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocJsonGet(options?: any): AxiosPromise<void> {
            return localVarFp.apiDocJsonGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiDocJsonGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiDocJsonGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnvironmentApi - axios parameter creator
 * @export
 */
export const EnvironmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @summary get config for frontend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppEnvironmentLogin: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/environment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnvironmentApi - functional programming interface
 * @export
 */
export const EnvironmentApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = EnvironmentApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @summary get config for frontend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppEnvironmentLogin(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnvironmentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAppEnvironmentLogin(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnvironmentApi - factory interface
 * @export
 */
export const EnvironmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnvironmentApiFp(configuration)
    return {
        /**
         *
         * @summary get config for frontend
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppEnvironmentLogin(options?: any): AxiosPromise<EnvironmentResponse> {
            return localVarFp.getAppEnvironmentLogin(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnvironmentApi - object-oriented interface
 * @export
 * @class EnvironmentApi
 * @extends {BaseAPI}
 */
export class EnvironmentApi extends BaseAPI {
    /**
     *
     * @summary get config for frontend
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentApi
     */
    public getAppEnvironmentLogin(options?: AxiosRequestConfig) {
        return EnvironmentApiFp(this.configuration).getAppEnvironmentLogin(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWebhook: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/webhook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = {method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postWebhook(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postWebhook(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postWebhook(options?: any): AxiosPromise<void> {
            return localVarFp.postWebhook(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public postWebhook(options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).postWebhook(options).then((request) => request(this.axios, this.basePath));
    }
}


