<template>
  <v-container fluid fill-height>
    <v-row align="center" justify="center">
      <v-col class="col-12 text-center">
        <v-col align="center" justify="center">
          <h1>404 - not found</h1>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "notFound",
};
</script>
<style></style>
