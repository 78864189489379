<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-alert v-if="isOld" border="top" color="red lighten-2" dark>
          No connection to server. Daemon or websocket server down!
        </v-alert>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :search="search"
          class="elevation-1"
          item-key="id"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              class="mx-4"
              label="Search"
            ></v-text-field>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-chip :color="getStatusVisualisation(item.status).color">
                    <v-icon color="white">
                      {{ getStatusVisualisation(item.status).icon }}
                    </v-icon>
                  </v-chip>
                </span>
              </template>
              <span>{{ getStatusVisualisation(item.status).text }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.lastSeen`]="{ item }">
            <relative-timer :time="item.lastSeen"></relative-timer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-icon class="pointer" small>mdi-help-circle</v-icon>
                </span>
              </template>
              <span>{{ getTimeFormatted(item.lastSeen) }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.options`]="{ item }">
            <v-btn
              :disabled="blocked"
              :loading="silence.loading === item"
              color="primary"
              small
              @click="silenceMetric(item)"
            >
              <v-icon v-if="item.status !== 2"> mdi-volume-off </v-icon>
              <v-icon v-else> mdi-volume-high </v-icon>
            </v-btn>
            <v-btn
              :disabled="item.status !== 1 || blocked"
              color="red"
              small
              @click="removeMetric(item)"
            >
              <v-icon color="white">mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="remove.confirm" width="500">
      <v-card>
        <v-card-title class="headline red lighten-1 white--text">
          <v-icon class="mr-4 white--text">mdi-alert</v-icon>
          WARNING
        </v-card-title>

        <v-card-text v-if="remove.metric" class="mt-5">
          Do you really want to delete metric {{ remove.metric.entityId }}?
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="
              remove.metric !== null && (remove.metric.status !== 1 || blocked)
            "
            :loading="remove.loading"
            color="red"
            text
            @click="removeMetricConfirmed"
          >
            Delete
          </v-btn>
          <v-btn
            :disabled="remove.loading"
            text
            @click="remove.confirm = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
import { DateTime } from "luxon";
import autobahn from "autobahn";
import RelativeTimer from "@/components/RelativeTimer";

export default {
  name: "AlertsControlView",
  components: { RelativeTimer },
  data() {
    return {
      blocked: true,
      interval: null,
      lastUpdate: null,
      connection: null,
      search: null,
      isOld: false,
      remove: {
        metric: null,
        confirm: false,
        loading: false,
      },
      silence: {
        loading: false,
      },
      loading: false,
      headers: [
        { text: "Metric", value: "metric" },
        { text: "Entity", value: "entity", width: "150px" },
        { text: "SubEntity", value: "subEntity" },
        { text: "Parent", value: "parent" },
        { text: "Status", value: "status", width: "100px", align: "center" },
        { text: "Error", value: "error", width: "100px", align: "center" },
        { text: "Last seen", value: "lastSeen", width: "160px" },
        { text: "Options", value: "options", width: "140px", sortable: false },
      ],
      items: [],
    };
  },
  methods: {
    checkIfOld() {
      this.isOld =
        !this.connection.isOpen ||
        (this.lastUpdate !== null &&
          DateTime.now().toSeconds() - 15 > this.lastUpdate);
    },
    async silenceMetric(item) {
      this.silence.loading = item;
      await axios.post("/api/alerts/silence", item);
      this.silence.loading = false;
    },
    async removeMetric(item) {
      this.remove.confirm = true;
      this.remove.metric = item;
    },
    async removeMetricConfirmed() {
      this.remove.loading = true;
      if (
        await axios.delete("/api/alerts/silence/" + this.remove.metric.metricId)
      ) {
        this.remove.confirm = false;
      }
      this.remove.loading = false;
    },
    getStatusVisualisation(status) {
      switch (status) {
        case 0:
          return {
            color: "red",
            icon: "mdi-bell-ring",
            text: "ALERT",
          };
        case 1:
          return {
            color: "red",
            icon: "mdi-help-box",
            text: "target is not sending metrics",
          };
        case 2:
          return {
            color: "orange",
            icon: "mdi-volume-mute",
            text: "silenced",
          };
        case 3:
          return {
            color: "grey",
            icon: "mdi-bell-sleep",
            text: "parent alert triggered, this check was omitted",
          };
        default:
          return {
            color: "green",
            icon: "mdi-check",
            text: "operational",
          };
      }
    },
    getTimeFormatted(time) {
      return DateTime.fromISO(time).toLocaleString(DateTime.DATETIME_FULL);
    },
    async loadData() {
      if (this.items.length === 0) {
        this.loading = true;
      }
      await axios
        .get("/api/alerts")
        .then((response) => {
          this.blocked = false;
          this.items = response.data;
        })
        .catch(() => {
          this.items = [];
        })
        .finally(() => {
          this.loading = false;
        });
      this.lastUpdate = DateTime.now().toSeconds();
    },
  },
  mounted() {
    this.loadData();
    this.connection = new autobahn.Connection({
      url: "wss://" + location.host + "/push",
      realm: "jfn-tools",
    });
    this.connection.onopen = (session) => {
      session.subscribe("com.jfnet.tools.alerts.refresh", () => {
        this.loadData();
      });
    };
    this.interval = setInterval(this.checkIfOld, 1000);
    this.connection.open();
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.connection.close();
  },
};
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
