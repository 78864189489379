import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "@/plugins/vuetify";
import toast from "vue-toastification";
import axios from "axios";
import Vuetify from "vuetify/lib";
import { createPinia, PiniaVuePlugin } from "pinia";

Vue.use(toast, {});
Vue.use(Vuetify);
Vue.use(PiniaVuePlugin);

Vue.config.productionTip = false;
axios.defaults.withCredentials = true;
const pinia = createPinia();

new Vue({
  router,
  vuetify,
  pinia,
  render: (h) => h(App),
}).$mount("#app");
