<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        Tickets ({{ item.issues.length }})
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :disable-pagination="true"
          :headers="issueHeaders"
          :hide-default-footer="true"
          :items="item.issues"
          dense
        >
          <template v-slot:[`item.estimate`]="{ item }">
            {{ checkEstimateTime(item.estimate, item.spentTotal, "") }}
            <span style="color: red; font-weight: bolder">{{
              checkEstimateTime(item.estimate, item.spentTotal, "red")
            }}</span>
          </template>
          <template v-slot:[`item.spent`]="{ item }">
            {{ humanTimeCustom(item.spent) }}
          </template>
          <template v-slot:[`item.spentTotal`]="{ item }">
            {{ humanTimeCustom(item.spentTotal) }}
          </template>
          <template v-slot:[`item.path`]="{ item }">
            <a :href="item.path" target="_blank"
              ><v-icon color="blue">mdi mdi-open-in-new</v-icon></a
            >
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import {
  parseTime,
  parseTimeCustom,
  checkEstimateTime,
} from "@/tools/humanTime";

export default defineComponent({
  name: "IssueList",
  props: {
    item: {},
  },
  data() {
    return {
      issueHeaders: [
        { text: "Estimate", value: "estimate", width: "175px" },
        { text: "Spent", value: "spent", width: "175px" },
        { text: "SpentTotal", value: "spentTotal", width: "175px" },
        { text: "Titel", value: "title", width: "300px" },
        { text: "Project", value: "project", width: "300px" },
        { text: "Link", value: "path" },
      ],
    };
  },
  methods: {
    humanTime(time: number) {
      return parseTime(time);
    },
    humanTimeCustom(time: number) {
      return parseTimeCustom(time);
    },
    checkEstimateTime(
      estimate: number | null,
      timeTotal: number | null,
      mode: string
    ) {
      return checkEstimateTime(estimate, timeTotal, mode);
    },
  },
});
</script>
