<template>
  <span>{{ display }}</span>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "RelativeTimer",
  props: {
    time: String,
    refresh: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      display: null,
      interval: null,
    };
  },
  mounted() {
    this.update();
    this.interval = setInterval(this.update, this.refresh);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    update() {
      this.display = DateTime.fromISO(this.time).toRelative();
    },
  },
};
</script>

<style scoped></style>
