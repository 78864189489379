import { defineStore } from "pinia";
import { EnvironmentApi, EnvironmentResponse } from "@/api";

export const useMainStore = defineStore("main", {
  state: () => ({
    env: {} as EnvironmentResponse,
  }),
  getters: {
    hasAccess: (state) => {
      return (role: string): boolean => {
        return state?.env?.user?.roles?.includes(role) ?? false;
      };
    },
  },
  actions: {
    async load(): Promise<void> {
      this.env = (await new EnvironmentApi().getAppEnvironmentLogin()).data;
    },
  },
});
